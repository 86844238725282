<script>
    import { createEventDispatcher } from 'svelte';
    import { Avatar } from '@skeletonlabs/skeleton';

    const dispatch = createEventDispatcher();

    export let name;
    export let showName = false;
    export let background = '1D5B79';
    export let color = 'FFFFFF';
    let avatarInitials = 'CS';

    $: {
        if (name) {
            avatarInitials = name.split(' ').map((n) => n[0]).join('').toUpperCase();
        }
    }
</script>

<a href='/me' on:click={() => dispatch('clickProfile')}>
    <div class='flex flex-row items-center gap-1'>
        <Avatar initials={avatarInitials}
                border='border-4 border-surface-300-600-token hover:!border-primary-100'
                background="bg-primary-500"
                width='w-12'
                fill='fill-white'
                cursor='cursor-pointer'/>
        {#if showName}<p class='text-primary-500'>{name}</p>{/if}
    </div>
</a>