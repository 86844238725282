<script>
    import Logo from '$components/Logo.svelte';
    import SearchDropdown from '$components/SearchDropdown.svelte';
    import {IconMenu2, IconSearch} from '@tabler/icons-svelte';
    import {drawerStore} from '@skeletonlabs/skeleton';
    import ProfileAvatar from '$components/profile/ProfileAvatar.svelte';
    import AddReviewCTA from '$components/AddReviewCTA.svelte';

    export let profile = {};

    let showSearch = false;
    let showProfile = false;

    $: showProfile = JSON.stringify(profile) !== "{}";

    function showDrawer() {
        showSearch = false;
        drawerStore.open({
            position: 'right',
            width: 'w-[200px]',
        })
    }
</script>

<!-- Desktop Layout -->
<div class='text-white pt-5 pb-3 md:pb-8 flex flex-row justify-between items-center'>
    <h1 class='ml-4 md:ml-2'>
        <a href='/'>
            <Logo />
        </a>
    </h1>
    <div class='hidden md:flex md:flex-grow mx-5'>
        <SearchDropdown />
    </div>
    <div class='hidden md:flex md:flex-row mr-5 gap-3'>
        <a class={`btn btn-sm px-0 text-black`} href='/buildings/search'>Search by Area</a>
        <a class={`btn btn-sm px-0 text-black`} href='/buildings/toronto'>All Buildings</a>
        <AddReviewCTA textSize='text-sm' buttonSize='btn-sm' classes='my-1' />
        {#if showProfile}<div class=''><ProfileAvatar name='{profile?.first_name} {profile?.last_name}' /></div>{/if}
    </div>
    {#if showSearch}
        <div class='flex flex-grow flex-row md:hidden mx-5 gap-5'>
            <SearchDropdown on:searchBlur={() => { showSearch = false }} />
        </div>
        <div class='flex flex-row md:hidden mr-5 gap-5'>
            <button on:click={showDrawer}><IconMenu2 color='black' /></button>
        </div>
    {:else}
        <div class='flex flex-row md:hidden mr-5 gap-5'>
            <button on:click={() => showSearch = true}><IconSearch color='black' /></button>
            <AddReviewCTA />
            <button on:click={showDrawer}><IconMenu2 color='black' /></button>
        </div>
    {/if}
</div>